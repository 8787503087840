<i18n lang="json">
{
	"ru": {
    "precost": "Забронировать",
    "linkPay": "Оплатить"
	},
	"en": {
    "precost": "Make an advance payment",
    "linkPay": "Pay"
	}
}
</i18n>
<template>
  <div class="form__block">
    <div class="form__block_half">
      <div class="half__item">
        <div class="booking-button">
          <a
            v-if="payLink"
            target="_blank"
            :href="payLink"
            class="button button_green button_size_md button_w-100"
            @click="goMain"
          >
            <span>{{ t("linkPay") }}</span>
          </a>
          <template v-else>
            <button
              v-if="bookingPrice"
              class="button button_green button_size_md button_w-100"
              :class="{ button_disabled: !sendButtonDisabled }"
              :disabled="!sendButtonDisabled"
              @click.prevent="send"
            >
              <p v-if="loadBookingPrice || loadUpdateOrderDraft" class="loader">
                <span></span>
              </p>
              <template v-else>
                <p v-if="btnView">
                  <span
                    style="
                      position: relative;
                      top: 2px;
                      font-size: 16px;
                      padding-right: 10px;
                    "
                    class="icon-app-lightning"
                  ></span>
                  <span>{{ t("precost") }}</span>
                </p>
                <p v-if="btnHold || !btnView">
                  <span>{{ t("precost") }}</span>
                </p>
              </template>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import _ from "lodash";
import { Booking } from "@/utils/gtm/";
import { Payments } from "@/components/api/";
import {Users} from "@/components/api/";
import { pushDataToYM } from "@/utils/yandexMetrica";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';

export default {
  name: "Button",
  mixins: [dataLayerPushGA4],
  props: {
    rightButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      btnSendWithPhone: false,
      toUrl: null,
      orderId: null,
      payLink: "",
      disableHoldReq: false, // запрет холдирования
      clickNotPay: false,
      timer: 0,
      timeout: null,
      phoneNoSend: [],
    };
  },
  watch: {
    loadBookingPrice(val) {
      this.$emit("buttonLoading", val);
    },
    timer(value) {
      clearTimeout(this.timeout);
      if (value > 0) {
        this.timeout = setTimeout(() => {
          this.timer -= 1;
        }, 1000);
      } else {
        this.clickNotPay = false;
      }
    },
    phoneSelected(val, oVal) {
      if (val !== oVal) {
        this.clickNotPay = false;
        this.timer = 0;
      }
    },
  },
  computed: {
    ...mapState("user", [
      "isAuth",
      "currency",
      "isVkApp",
      "userData",
      "viewPort",
      "whitelabel"
    ]),
    ...mapState("booking", [
      "password",
      "firstCheck",
      "getPassword",
      "bookingNow",
      "loadBookingPrice",
      "loadUpdateOrderDraft",
      "draftType",
      "warningInfo",
      "phoneSelected",
      "viewAsidePhone",
      "aproovePhone",
    ]),
    ...mapGetters("booking", [
      "params",
      "paramsWarning",
      "bookingPrice",
      "objectData",
      "bookingPriceError",
      "isDisabledBooking",
      "isNoValidPetsDesc"
    ]),
    ...mapGetters("detail", ["getDataForYM"]),
    checkPhone() {
      return this.phoneSelected &&
      String(this.phoneSelected).match(/\d/g).length === 11 &&
      String(this.phoneSelected).match(/\d/g)[0] == 7 &&
      this.viewAsidePhone
        ? true
        : false;
    },
    phoneCorrect() {
      let number = this.params.phone
        ? String(this.params.phone).match(/\d/g)
        : null;
      if (number && number.length >= 11 || number.length === 10) {
        return true;
      } else {
        return false;
      }
    },
    btnInit() {
      if (this.bookingPrice) {
        if (
          this.bookingPrice.is_booking_now ||
          this.bookingPrice.is_booking_no_precost ||
          this.bookingPrice.is_object_hot
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    btnView() {
      if (this.draftType === "mb" || this.draftType === "hot") {
        this.setBookingNow(true);
        return true;
      } else if (this.draftType === "order") {
        this.setBookingNow(false);
        return false;
      }
      if (this.btnInit) {
        if (
          this.params &&
          ((this.params.is_doc && this.paramsWarning.documents) ||
            (this.params.is_pets && this.paramsWarning.pets) ||
            (this.params.target == 4 && this.paramsWarning.party) ||
            this.params.is_legal_person)
        ) {
          this.setBookingNow(false);
          return false;
        } else {
          this.setBookingNow(true);
          return true;
        }
      } else {
        this.setBookingNow(false);
        return false;
      }
    },
    sendWithPhone() {
      if (
        (!this.phone || this.phone == "") &&
        this.$refs &&
        this.$refs.phone &&
        this.$refs.phone &&
        this.$refs.phone.flags &&
        this.$refs.phone.flags.valid
      ) {
        return true;
      } else {
        if (!this.btnSendWithPhone) {
          return true;
        } else {
          return false;
        }
      }
    },
    sendButtonDisabled() {
      if (this.isDisabledBooking) return false;
      if (
        this.params.date_begin &&
        this.params.date_end &&
        !(this.bookingPriceError.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    prepayment() {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.bookingPrice?.currency?.code,
        minimumFractionDigits: 0,
      }).format(this.bookingPrice?.precost);
    },
    btnHold() {
      const CAN_HOLD =
        !this.btnView &&
        this.isDefaultData &&
        this.isDefaultTime &&
        this.userBalance === 0 &&
        this.bookingPrice?.can_hold;
      this.setCanHold(CAN_HOLD);
      return CAN_HOLD;
    },
    userBalance() {
      return this.userData?.balance
        ? Number(this.userData.balance.split("<span")[0].replace(/\s/g, ""))
        : 0;
    },
    isDefaultData() {
      return !(
        this.params &&
        (this.params.is_doc ||
          this.params.is_pets ||
          this.params.target === 4 ||
          this.params.is_legal_person)
      );
    },
    isDefaultTime() {
      const TIME_BEGIN = this.warningInfo.time_begin;
      const TIME_END = this.warningInfo.time_end;
      return !(
        TIME_BEGIN.error ||
        TIME_END.error ||
        TIME_BEGIN.warning ||
        TIME_END.warning
      );
    },
  },
  methods: {
    ...mapActions("booking", [
      "updateParams",
      "bookingToAction",
      "authAction",
    ]),
    ...mapMutations("booking", [
      "setBookingNow",
      "loadBookingMutate",
      "firstCheckMutate",
      "getPasswordMutate",
      "setCanHold",
      "setPetsDescriptionError",
      "setNameEntered",
      "setIsErrorUserName",
    ]),
    ...mapMutations("modal", ["setModalType"]),
    sendSmsWithPhone() {
      if (this.params.phone) {
        this.btnSendWithPhone = true;
        this.forgot();
      }
    },
    async onSubmit() {
      const result = await this.$refs.observerFields.validate();
    },
    async forgot() {
      this.$nextTick(() => {
        this.password = "";
      });
      this.auth({ status: "auth-forgot-last", forgot: 1 });
    },
    send() {
      if(this.isNoValidPetsDesc){
        this.setPetsDescriptionError(true)
        return false;
      }

      if (this.sendButtonDisabled) {
        if (this.rightButton) {
          Booking.gaRightButton();
        } else {
          Booking.gaBottomButton();
        }
        document.querySelector("#contacts").scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        if (this.params?.name) {
          this.setIsErrorUserName(false);
        } else {
          this.setIsErrorUserName(true);
        }

        if(this.params?.name) {
          this.auth({ status: "auth-registration-last" })
          .then(() => {
            if (this.params.email && this.params.email !== "") {
              //после ответа авторизации передаем mail если заполнен
              this.isPhoneError = false;
              let formData = new FormData();
              formData.append("contactValue", this.params.email);
              formData.append("typeId", "1");
              formData.append("isLandline", "0");

              Users.addContact(formData)// добавим майл в лк
                .then((response) => {
                  // если нас просит редиректнуть на повторную отправку, то выходим
                  const requestApproveContact = (email) => {
                    let formData = new FormData();
                    formData.append("value", email);
                    Users.requestApproveContact(formData)
                      .then(res => this.timers = res.data.data.timeout)
                      .catch(err => console.error(err));
                  }
                  if (
                    response.data.actions &&
                    response.data.actions.redirect === "users/requestApproveContact"
                  ) {
                    requestApproveContact(this.params.email);//вышлем подтверждение на мыло
                  } else if (response.data.errors.length) {
                    console.warn(response.data.errors);
                  }
                  // есть еще сценарий где в ответе приходит контакт, но это неприципиально т.к.
                  // requestApproveContact приходится запрашивать в любом случае
                })
                .catch(err => console.warn(err));
            }
          })
        } else {
          this.setNameEntered(false);
          setTimeout(() => {
            document.querySelector('.js-input-name')?.focus();
          }, 400);
        }
      }

      const dataForYM = this.getDataForYM('add', this.$route.name);
      pushDataToYM(dataForYM);
    },
    async auth({
      name = this.params.name,
      value = this.params.phone,
      password = this.password,
      status = "auth-login",
      forgot = 0,
      persistent = 1,
      terms = 1,
    }) {
      let number = value ? String(value).match(/\d/g) : null;
      if (!this.getPassword && number && number.length >= 11 || number.length === 10) {
        this.getPasswordMutate(true);
      }
      if (this.phoneCorrect) {
        return await this.authAction({
          name,
          value,
          password,
          status,
          forgot,
          persistent,
          terms,
        }).then(async (response) => {
          if (response.data.data.user_id) {
            return await this.bookingSend();
          }
        });
      }
    },
    async bookingSend() {
      await this.bookingToAction({
        order_code: this.params.code,
        no_matter: this.noMatter,
        cost: this.bookingPrice.price,
        precost: this.bookingPrice.precost,
      }).then((response) => {
        this.$store.commit("booking/clearBookingToErrors");
        if (response?.data?.data?.to_url) {
          this.bookingAnalytics();
          if (this.btnHold) {
            this.orderId = response?.data?.data?.order_id;
            //холдирование средств и получение ссылки
            if (response?.data?.data?.to_url) {
              location.href = response.data.data.to_url
            }
          } else if (this.btnView) {
            this.router(response.data.data.to_url, response.data.data.link_pay);
          } else {
            this.setModalType("ModalRequest");
            this.router();
          }
        } else if (response.data.errors && Object.keys(response.data.errors).length) {
          this.$store.commit('booking/setBookingToErrors', response.data.errors);
        }
      });
    },
    bookingAnalytics() {
      Booking.gaEventBooking(
        this.objectData.location.city,
        this.bookingPrice.price
      );
      Booking.ymSendForm();
      // https://jira.webpower.ru/browse/VUECLIENT-1811
      this.dataLayerPush("begin_checkout")
      // Booking.gaSendProduct(
      //   this.params.object_id,
      //   this.ecommerceItems[0]["item_name"],
      //   this.bookingPrice.price,
      //   this.objectData.location.city,
      //   this.ecommerceItems[0]["item_category"],
      //   this.ecommerceItems[0]["item_category2"],
      //   this.ecommerceItems[0]["item_variant"],
      //   this.objectData.position,
      //   this.ecommerceItems[0]["quantity"],
      //   this.params.guests.length
      // );
      Booking.gtmStep2(this.params.object_id, this.params.email ? this.params.email : `${this.params.phone}@unknown.email`);
    },
    router(url = null, payLink) {
      if (url) {
        if (this.isVkApp) {
          /**
          @tag vk-app */
          if (payLink) {
            this.payLink = payLink;
          }
          // пока оставим
          // this.$router.push({
          //   name: "PaymentsFrame",
          //   params: { paymentsUrl: url },
          // });
        } else if (this.btnHold && !this.whitelabel) {
          // берем чистый урл, потому что при холдировании всегда на юмани отправляет
          // проверка на блокировку всплывающих окон
          const WINDOW_PAY = window.open(url, "_blank");
          if (WINDOW_PAY) {
            document.location.href = `/cabinet/notices/${this.orderId}`;
          } else {
            document.location.href = url;
          }
        } else if (this.whitelabel && payLink) {
          window.open(payLink);
        } else if (this.btnHold && window.open()) {
          const ROUTE = this.$router.resolve({
            name: "Payments",
            params: { paymentsUrl: url },
          });
          // проверка на блокировку всплывающих окон
          const WINDOW_PAY = window.open(ROUTE.href, "_blank");
          if (WINDOW_PAY) {
            document.location.href = `/cabinet/notices/${this.orderId}`;
          } else {
            this.$router.push({
              name: "Payments",
              params: { paymentsUrl: url },
            });
          }
        } else {
          this.$router.push({
            name: "Payments",
            params: { paymentsUrl: url },
          });
        }
      } else {
        this.setModalType("ModalRequest");
        const { id, title: term } = this.getObjectDataMixin?.location?.city || {};
        const query = JSON.parse(localStorage.getItem("query")) ?? { id, term }
        this.$router.push({
          name: "Search",
          query,
        });
      }
    },
    goMain() {
      this.$router.push({
        name: "Main",
        query: {
          afterPayInfo: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .booking__pay-bold {
    font-weight: bold;
  }
  .is-valid {
    border: 1px solid #28a745;
  }
  .form__button {
    padding-top: 25px;
  }
  .form__prepay {
    color: #444;
    padding-top: 10px;
    font-size: 14px;
    line-height: 16px;
  }
  .text-invalid {
    padding-top: 5px;
    color: #e10600;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .base-modal {
    &-body {
      padding: 10px;
    }
  }
}
.booking-button {
  &__modal {
    &-text:not(:first-child) {
      margin-top: 10px;
    }
    &-footer {
      margin-top: 30px;
    }
    &-buttons {
      display: flex;
      justify-content: flex-end;
      .button:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
.loader {
  position: relative;
  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
    width: 25px;
    height: 25px;
    &:after {
      display: block;
      width: 25px;
      height: 25px;
      border: 3px solid #d8d8d8;
      border-top-color: #f51449;
      content: "";
      border-radius: 50%;
      -webkit-animation: spin 1s infinite linear;
      animation: spin 1s infinite linear;
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
.button_w-100 {
  text-align: center;
}
</style>
