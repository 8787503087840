// ["@mapbox/timespace", "moment-timezone"] для определения времени по локации
// пока бэк не возвращает время арендодателя, получаем сами на клиенте
import ts from "@mapbox/timespace";
import moment from "moment-timezone";
import format from "@/utils/format";

export default {
  computed: {
    getObjectDataMixin() {
      return this.$store.getters['detail/getObjectData'];
    },
    objectDataMixin() {
      return this.$store.state.booking?.objectData;
    },
    checkInMixin() {
      return this.$store.state.search?.checkIn;
    },
    objectTime() {
      const objectData = this.getObjectDataMixin ? this.getObjectDataMixin : this.objectDataMixin;
      const point = [objectData?.location?.lng, objectData?.location?.lat];
      const time = ts.getFuzzyLocalTimeFromPoint(Date.now(), point);
      if (!time) {
        return null;
      }
      const objectTime = moment.tz(time["_z"]["name"]);
      return {
        day: objectTime.format("YYYY-MM-DD"),
        hour: objectTime.format("HH"),
      };
    },
    isTodayBooking() {
      const dateBegin = this.checkInMixin ? format(new Date(this.checkInMixin)) : undefined;
      const check_in_out_rules = this.getObjectDataMixin?.properties?.check_in_out_rules?.properties.find(item => item.name === "check_in");
      const checkIn = check_in_out_rules ? Number(check_in_out_rules.value.split(":")[0]) : this.objectDataMixin?.date?.in;

      return dateBegin === this.objectTime?.day && Number(this.objectTime?.hour) >= checkIn;
    },
  }
}
