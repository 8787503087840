<template>
  <div class="placeholder-loader placeholder-loader_content">
    <div class="shadow"></div>
    <div class="text">
      <div class="text__item text__item_xs"></div>
      <div class="text__item text__item_xs"></div>
    </div>

    <div class="calc">
      <div class="calc__item calc__multi"></div>
      <div class="calc__item calc__sum"></div>
    </div>

    <div class="price">
      <div class="price__item"></div>
    </div>

    <div class="price">
      <div class="price__item price__item_xs"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Placeholder",
};
</script>

<style lang="scss" scoped>
.placeholder-loader {
  position: relative;
  padding: 20px;
  overflow: hidden;
  background: #fff;

  .text__item,
  .calc__item,
  .price__item {
    background: #d8d8d8;
    border-radius: 10px;
    width: 100%;
    margin-top: 10px;
  }

  .text {
    &:first-child {
      margin-top: 0;
    }
    &__item {
      &_xs {
        height: 20px;
      }
    }
  }

  .calc {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    &__multi {
      height: 32px;
      width: 110px;
    }
    &__sum {
      height: 20px;
      width: 60px;
    }
  }

  .price {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &__item {
      height: 40px;
      width: 110px;

      &_xs {
        height: 20px;
        width: 140px;
      }
    }
  }

  .shadow {
    position: absolute;
    left: 0;
    top: -10%;
    bottom: -10%;
    width: 20px;
    opacity: 0;
    background: #fff;
    animation: loader 1s linear infinite;
    filter: blur(20px);
    transform: rotate(25deg);
  }
}

@keyframes loader {
  from {
    left: 0;
    opacity: 0;
  }
  10% {
    left: 10%;
    opacity: 1;
  }
  90% {
    left: 90%;
    opacity: 1;
  }
  to {
    left: 110%;
    opacity: 0;
  }
}
</style>
