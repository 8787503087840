<template>
  <div class="partner-modal" :class="{ partner_mobile: isMobile }">
    <div class="partner-modal_top">
      <div class="partner-modal_logo">
        <img :src="logoMob" class="img"/>
      </div>
      <p class="partner-modal_text">Бронирование будет происходить через партнёрский сервис Суточно.ру</p>
    </div>
    <div v-if="isMobile" class="partner-modal_advantages">
      <p class="advantages__title">Преимущества сервиса:</p>
      <div class="advantages__list">
        <div class="advantages__item">
          <div class="advantages_marker" />
          <div class="advantages_text">проверенное жилье</div>
        </div>
        <div class="advantages__item">
          <div class="advantages_marker" />
          <div class="advantages_text">гарантия успешного заселения</div>
        </div>
        <div class="advantages__item">
          <div class="advantages_marker" />
          <div class="advantages_text">оплата без скрытых комиссий</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logoMob from '@/assets/img/logo-mob-new.svg'

export default {
  name: "PartnerHint",
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logoMob
    }
  }
};
</script>

<style lang="scss" scoped>
.partner-modal {
  padding: 20px;

  &.partner_mobile {
    padding: 0 0 20px 0;
  }

  &_top {
    display: flex;
    align-items: center;
  }

  &_logo {
    width: 47px;
    height: 40px;

    .desktop & {
      width: 37px;
      height: 30px;
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }
  &_text {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-top: 0;
    margin-left: 13px;

    .desktop & {
      font-size: 13px;
    }
  }
  &_advantages {
    margin-top: 13px;

    .advantages {
      &__title {
        color: #000;
      }
      &__list {
        margin-top: 13px;
      }
      &__item {
        display: flex;
        align-items: center;
        margin-top: 5px;
        &:first-child {
          margin-top: 0;
        }
      }
      &_marker {
        width: 14px;
        height: 14px;
        background-size: contain;
        background-image: url('~/assets/img/fire.png');
      }
      &_text {
        padding-left: 10px;
        color: #000;
      }
    }
  }
}
</style>
